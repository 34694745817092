<template>
  <FinalField :name="name">
    <template v-slot="props">
      <div class="form-col">
        <label>{{label}}</label>
        <div class="flex" :class="{'flex-col': !rowView, 'flex-wrap': rowView}">
          <label
              v-for="option in options"
              :key="option[itemKey]"
              class="checkbox-label"
              :class="{'cursor-pointer': editMode, 'mr-6': rowView}"
          >
            <input
                v-if="editMode"
                type="checkbox"
                :name="props.name"
                :checked="(props.value || []).includes(getOptionKey(option))"
                :disabled="option.disabled"
                @change="handleChange(props, getOptionKey(option))"
            >
            <icon
                v-if="!editMode"
                class="inline"
                :name="props.value && props.value.includes(getOptionKey(option)) ? 'ok' : 'cancel'"
            />
            {{option[itemLabel]}}
          </label>
        </div>
      </div>
    </template>
  </FinalField>
</template>

<script>
  import {FinalField} from 'vue-final-form';
  import Icon from '@/components/ui/Icon';

  export default {
    name: "CheckboxGroupInput",
    components: {
      FinalField,
      Icon,
    },
    props: {
      name: {
        type: String,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      options: {
        type: Array,
        required: true,
      },
      editMode: {
        type: Boolean,
        default: true,
      },
      objectMode: {
        type: Boolean,
        default: false,
      },
      itemKey: {
        type: String,
        default: 'key',
      },
      itemLabel: {
        type: String,
        default: 'value',
      },
      rowView: {
        type: Boolean,
        default: false,
      },
    },
    methods: {
      getOptionKey(option) {
        return this.objectMode ? option : option[this.itemKey];
      },

      handleChange(props, key) {
        const set = new Set(props.value);
        if (set.has(key)) {
          set.delete(key);
        } else {
          set.add(key);
        }
        props.change(Array.from(set));
      },
    },
  }
</script>

<style scoped>
  .form-col label.checkbox-label {
    font: inherit;
  }
  input[type="checkbox"] {
    vertical-align: -2px;
  }
</style>
