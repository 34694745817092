<template>
<div class="flex flex-col h-full">
  <FiltersBlock v-if="bulkMode" readonly/>
  <div v-if="printSectionOptions.length" class="border mb-6 p-4">

    <FinalForm
        v-if="printStatus === PRINT_STATUS_NONE || printStatus === PRINT_STATUS_FAILED"
        ref="form"
        :submit="handleSubmit"
        :initial-values="{printOptions: []}"
    >
      <template v-slot="props">
        <form @submit="props.handleSubmit">
          <div class="form-row">
            <CheckboxGroupInput
                name="printOptions"
                label="select additional sections to print:"
                :options="printSectionOptions"
                item-key="code"
                item-label="name"
                object-mode
                row-view
            />
          </div>
          <div class="form-row">
            <button class="btn-primary btn-solid" :disabled="selectedIds.length < 1">print</button>
          </div>

          <div v-if="printStatus === PRINT_STATUS_FAILED" class="form-hint">
            Something went wrong. Please try again.
          </div>
        </form>
      </template>
    </FinalForm>

    <template v-else-if="printStatus === PRINT_STATUS_WAITING">
      <div class="text-sm">
        Preparing your document. Please wait...
      </div>
    </template>

    <template v-else-if="printStatus === PRINT_STATUS_READY">
      <div class="text-sm">
        Your document is ready. <a :href="documentLink" target="_blank" class="text-link">Click here</a> to open it.
      </div>
    </template>
  </div>
  <div class="flex-grow overflow-auto">
    <List
        :columns="columns"
        :items="requests"
    >
      <template v-slot:column-head:check>
        <input ref="headerCheckbox" type="checkbox" @change="handleMassCheck"/>
      </template>
      <template v-slot:column:check="{item}">
        <input type="checkbox" v-model="item.checked" class="ml-1"/>
      </template>
      <template v-slot:column:title="{item}">
        {{item.data.title}}<br>
        <span class="text-gray-500">{{item.systemTitle}}</span>
      </template>
      <template v-slot:column:createdAt="{ value }">
        <community-date-time-display :input="value" format="MM/DD/YYYY"/>
      </template>
      <template v-slot:column:metadata.assignee="{ value }">
        {{ getFullName(value) }}
      </template>
      <template v-slot:column:category="{item: {metadata: {category, customCategory}}}">
        <div>{{ category?.parent?.name || category?.name || customCategory || '-' }}</div>
        <div v-if="category?.parent || (category && customCategory)" class="text-gray-500">
          {{ category?.parent ? category.name : customCategory }}
        </div>
      </template>
    </List>
  </div>
  <loader :loading="loading" backdrop/>
</div>
</template>

<script>
import FiltersBlock from "@/components/sreq/list/FiltersBlock";
import ModalNavigation from "@/mixins/ModalNavigation";
import {mapGetters} from "vuex";
import List from "@/components/list/List";
import CommunityDateTimeDisplay from "@/components/ui/CommunityDateTimeDisplay";
import {FinalForm} from 'vue-final-form';
import CheckboxGroupInput from "@/components/form/CheckboxGroupInput";
import Loader from "@/components/ui/Loader";
import NotifyMixin from "@/mixins/NotifyMixin";
import EventBus from "@/utils/EventBus";
import ConfirmationMixin from "@/mixins/ConfirmationMixin";

const PRINT_STATUS_NONE = 'PRINT_STATUS_NONE';
const PRINT_STATUS_WAITING = 'PRINT_STATUS_WAITING';
const PRINT_STATUS_READY = 'PRINT_STATUS_READY';
const PRINT_STATUS_FAILED = 'PRINT_STATUS_FAILED';

export default {
  components: {Loader, CheckboxGroupInput, CommunityDateTimeDisplay, List, FiltersBlock, FinalForm},

  mixins: [ModalNavigation, NotifyMixin, ConfirmationMixin],

  data() {
    return {
      PRINT_STATUS_NONE,
      PRINT_STATUS_WAITING,
      PRINT_STATUS_READY,
      PRINT_STATUS_FAILED,

      printSectionOptions: [],
      requests: [],
      loading: false,
      printStatus: PRINT_STATUS_NONE,
      documentLink: 'google.com',
      printJobId: null,
    };
  },

  computed: {
    ...mapGetters({
      filterValues: 'sreq/listFilterValues',
    }),

    bulkMode() {
      return this.$route.name === 'sreq.print.bulk';
    },

    columns() {
      return [
        this.bulkMode && {
          name: 'check',
          title: 'check',
          class: 'w-1/12 px-4',
        },
        {
          name: 'title',
          title: 'title',
          class: 'w-3/12',
        },
        {
          name: 'createdAt',
          title: 'creation date',
          class: 'w-2/12',
        },
        {
          name: 'metadata.assignee',
          title: 'assigned to',
          class: 'w-2/12',
        },
        {
          name: 'category',
          title: 'category',
          class: 'w-2/12',
        },
        {
          name: 'metadata.currentState.name',
          title: 'status',
          class: 'w-2/12',
        },
      ].filter(c => c);
    },

    selectedIds() {
      return this.requests.filter(({checked}) => checked).map(({id}) => id);
    },
  },

  watch: {
    selectedIds(ids) {
      if (!this.bulkMode) return;

      if (ids.length !== this.requests.length) {
        this.$refs.headerCheckbox.indeterminate = ids.length > 0;
        this.$refs.headerCheckbox.checked = false;
      } else {
        this.$refs.headerCheckbox.indeterminate = false;
        this.$refs.headerCheckbox.checked = true;
      }
    },
  },

  methods: {
    handleSubmit({printOptions}) {
      this.loading = true;
      this.printStatus = PRINT_STATUS_WAITING;
      this.$sreqDataProvider.createAsync('print', {
        data: {
          printOptions,
          requestIds: this.selectedIds,
        }
      }).then(({id}) => {
        this.printJobId = id;
      }).catch(error => {
        this.notifyError(error.message);
        this.printStatus = PRINT_STATUS_FAILED;
      }).finally(() => {
        this.loading = false;
      });
    },

    handleMassCheck(event) {
      this.requests.forEach(request => {
        request.checked = event.target.checked;
      });
    },

    makeRequestParams() {
      const params = {};

      if (this.filterValues.createdAt) {
        params.creationDateFrom = this.filterValues.createdAt.startDate;
        params.creationDateTo = this.filterValues.createdAt.endDate;
      }

      if (this.filterValues.assignees) {
        params.assignees = this.filterValues.assignees;
      }

      if (this.filterValues.categories) {
        params.categories = this.filterValues.categories;
      }

      if (this.filterValues.statuses) {
        params.statuses = this.filterValues.statuses;
      }

      if (this.filterValues.priorities) {
        params.priorities = this.filterValues.priorities;
      }

      if (this.filterValues.searchQuery) {
        params.q = this.filterValues.searchQuery;
      }

      if (this.$route.query.locationId) {
        params.locationId = this.$route.query.locationId;
      }

      return params;
    },

    getFullName(person) {
      return person ? `${person.firstName} ${person.lastName}` : '-';
    },

    processNotifications(notification) {
      switch (notification.channel) {
        case 'SREQ/print_response_ready':
          if (notification.body.requestId === this.printJobId) {
            this.printStatus = PRINT_STATUS_READY;
            this.documentLink = notification.body.url;
          }
          break;
        case 'SREQ/print_response_failed':
          if (notification.body.requestId === this.printJobId) {
            this.printStatus = PRINT_STATUS_FAILED;
          }
      }
    },
  },

  mounted() {
    const requestsPromise = this.bulkMode ?
        this.$sreqDataProvider.getSimpleList('serviceRequests', this.makeRequestParams()).then(response => {
          this.requests = response.map(item => ({...item, checked: true}));
        }) :
        this.$sreqDataProvider.getOne('serviceRequests', {id: this.$route.params.requestId}).then(response => {
          this.requests = [{...response, checked: true}];
        });
    this.loading = true;
    Promise.all([
      requestsPromise,
      this.$sreqDataProvider.getSectionOptions('print').then(response => {
        this.printSectionOptions = response;
      }),
    ]).catch(error => {
      this.notifyError(error.message);
    }).finally(() => {
      this.loading = false;
    });

    EventBus.on('wst-notification', this.processNotifications);
  },

  beforeUnmount() {
    EventBus.off('wst-notification', this.processNotifications);
  },

  async beforeRouteLeave(to, from, next) {
    if (this.printStatus === PRINT_STATUS_WAITING) {
      const result = await this.requestConfirmation({
        confirmationMessage: 'are you sure you want to leave? you will have to request the document for printing again.',
        confirmBtnText: 'leave',
        cancelBtnText: 'cancel',
        confirmationType: 'warning',
      });
      next(result);
    } else {
      next();
    }
  },
}
</script>

<style scoped>

</style>
